import { createSlice } from "@reduxjs/toolkit"
import {
    retrieveDashboardData,
    createPanel,
    retrievePanel
} from "./panelActions"

const initialState = {
    loading: {
        createPanel: false,
        getPanel: false,
    },
    error: {
        createPanel: false,
        getPanel: false,
        dashboard: false,
    },
    installationAddress: null,
    installationDate: null,
    nominalCapacity: null,
    elapsedTime: null,
    tkSoluctionBasicLevel: null,
    tkSoluctionBasicPh: null,
    tkGlycerolLevel: null,
    tkGlycerolTemperature: null,
    tkGlycerolPump: null,
    tkGlycerolPh: null,
    luminosity: null,
    temperature: null,
    gasFlow: null,
    success: false
}

const panelSlice = createSlice({
    name: 'panel',
    initialState,
    reducers: {
        resetDashboardState: (state) => {
            state.tkSoluctionBasicLevel = null
            state.tkSoluctionBasicPh = null
            state.tkGlycerolLevel = null
            state.tkGlycerolTemperature = null
            state.tkGlycerolPump = null
            state.tkGlycerolPh = null
            state.luminosity = null
            state.temperature = null
            state.gasFlow = null
        },
        resetPanelState: (state) => {
            state.installationAddress = null
            state.installationDate = null
            state.nominalCapacity = null
            state.elapsedTime = null
        },
        resetSuccessState: (state) => {
            state.success = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(retrieveDashboardData.pending, (state) => {
                state.error = null
            })
            .addCase(retrieveDashboardData.fulfilled, (state, action) => {
                state.tkSoluctionBasicLevel = action.payload.tkSoluctionBasicLevel
                state.tkSoluctionBasicPh = action.payload.tkSoluctionBasicPh
                state.tkGlycerolLevel = action.payload.tkGlycerolLevel
                state.tkGlycerolTemperature = action.payload.tkGlycerolTemperature
                state.tkGlycerolPump = action.payload.tkGlycerolPump
                state.tkGlycerolPh = action.payload.tkGlycerolPh
                state.luminosity = action.payload.luminosity
                state.temperature = action.payload.temperature
                state.gasFlow = action.payload.gasFlow
            })
            .addCase(retrieveDashboardData.rejected, (state) => {
                state.error.dashboard = true
            })
            .addCase(createPanel.pending, (state) => {
                state.loading.createPanel = true
                state.error = null
            })
            .addCase(createPanel.fulfilled, (state) => {
                state.loading.createPanel = false
                state.success = true
            })
            .addCase(createPanel.rejected, (state, action) => {
                state.loading.createPanel = false
                state.error.createPanel = true
            })
            .addCase(retrievePanel.pending, (state) => {
                state.loading.getPanel = true
                state.error = null
            })
            .addCase(retrievePanel.fulfilled, (state, action) => {
                state.loading.getPanel = false
                if (action.payload !== undefined) {
                    state.installationAddress = action.payload.installationAddress
                    state.installationDate = action.payload.installationDate
                    state.nominalCapacity = action.payload.nominalCapacity
                    state.elapsedTime = action.payload.elapsedTime
                }
            })
            .addCase(retrievePanel.rejected, (state, action) => {
                state.loading.getPanel = false
                state.error.getPanel = true
            })
    },
})

export const { resetDashboardState, resetPanelState, resetSuccessState } = panelSlice
export default panelSlice.reducer
