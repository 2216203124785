import { I18n } from "../components/I18n/I18n.jsx"
import { Sidebar } from "../components/Sidebar/Sidebar"
import { Select } from "../components/Select/Select"
import { LineChart } from "../components/Charts/LineChart"
import { LinePointedChart } from "../components/Charts/LinePointedChart"
import { Pill } from "../components/Pill/Pill"
import { Box } from "../components/PhotocatalyticPanel/Box"
import { useTranslation } from "react-i18next"
import Sun from "../assets/sun.svg"
import Moisture from "../assets/moisture.svg"
import { MdOutlineClose } from "react-icons/md"
import { IoMdTime } from "react-icons/io"
import { IoSettingsOutline, IoSunnyOutline } from "react-icons/io5"
import { IoNotificationsOutline } from "react-icons/io5"
import { BsInfoCircle } from "react-icons/bs"
import { FaArrowRightLong, FaTemperatureHalf, FaWind } from "react-icons/fa6"
import React, { useState } from "react"
import { ReactComponent as OrangeArrowBr } from "../assets/orange-arrow-br.svg"
import { ReactComponent as BlueArrowR } from "../assets/blue-arrow-r.svg"
import { ReactComponent as BlueArrowTR } from "../assets/blue-arrow-tr.svg"
import { ReactComponent as BlueArrowRBL } from "../assets/blue-arrow-rbl.svg"
import { ReactComponent as GreenArrowTRB } from "../assets/green-arrow-trb.svg"
import { ReactComponent as BlueTube } from "../assets/blue-tube.svg"
import { ReactComponent as GreenTube } from "../assets/green-tube.svg"
import { ReactComponent as OrangeWaterPump } from "../assets/orange-water-pump.svg"
import { ReactComponent as BlueWaterPump } from "../assets/blue-water-pump.svg"
import { ReactComponent as Temperature } from "../assets/temperature.svg"
import { ReactComponent as Reactor } from "../assets/reactor.svg"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { createPanel, retrieveDashboardData, retrievePanel } from "../features/panel/panelActions.js"
import { Modal } from "../components/Modal/index.jsx"
import { Button } from "../components/Button/Button.jsx"
import { Input } from "../components/Input/index.jsx"
import { resetSuccessState } from "../features/panel/panelSlice.js"

export function DashboardPage() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [openInfo, setOpenInfo] = useState(false)
    const [openConfig, setOpenConfig] = useState(false)

    const { tkSoluctionBasicLevel, tkSoluctionBasicPh, tkGlycerolLevel, tkGlycerolTemperature,
        tkGlycerolPump, tkGlycerolPh, luminosity, temperature, gasFlow,
        installationAddress, installationDate, nominalCapacity, elapsedTime,
        success} = useSelector(
            (state) => state.panel
        )

    useEffect(() => {
        if (success) {
            setOpenConfig(false)
            dispatch(resetSuccessState())
        }
        dispatch(retrieveDashboardData())
        if (openConfig || openInfo) {
            dispatch(retrievePanel())
        }
    }, [dispatch, openConfig, openInfo, success])


    const months = [
        { value: 'jan', label: t('january') },
        { value: 'feb', label: t('february') },
        { value: 'mar', label: t('march') },
        { value: 'apr', label: t('april') },
        { value: 'may', label: t('may') },
        { value: 'jun', label: t('june') },
        { value: 'jul', label: t('july') },
        { value: 'aug', label: t('august') },
        { value: 'sep', label: t('september') },
        { value: 'oct', label: t('october') },
        { value: 'nov', label: t('november') },
        { value: 'dec', label: t('december') }
    ]

    const d = new Date()
    const day = d.getUTCDate()
    const month = d.getUTCMonth()
    const year = d.getUTCFullYear()

    const [fromDay, setFromDay] = useState(day.toString())
    const [toDay, setToDay] = useState(day.toString())
    const [fromMonth, setFromMonth] = useState(months[month].value)
    const [toMonth, setToMonth] = useState(months[month].value)
    const [fromYear, setFromYear] = useState(year.toString())
    const [toYear, setToYear] = useState(year.toString())

    const commands = [
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
    ]

    const [instAdr, setInstAdr] = useState(installationAddress === null ? "" : installationAddress)
    const [instDate, setInstDate] = useState(installationDate === null ? "" : installationDate)
    const [nomCapc, setNomCapc] = useState(nominalCapacity === null ? "" : nominalCapacity)
    const [elsTime, setElsTime] = useState(elapsedTime === null ? "" : elapsedTime)

    const configPanel = (e) => {
        e.preventDefault();


        dispatch(createPanel(instAdr, instDate, nomCapc, elsTime))
    }

    return (
        <div className="flex bg-h2v-dashboard-background h-screen overflow-hidden">
            <Sidebar />
            <div className="flex flex-col w-full h-full overflow-auto">
                <div className="flex flex-row bg-white mt-4 mx-4 p-4 px-6 rounded-xl items-center justify-between">
                    <div className="flex flex-row items-center space-x-4">
                        <Pill
                            leadingIcon={<img src={Sun} className="mr-2 object-cover" alt="Sun" />}
                            children={<p className="font-poppins font-medium text-h2vsmalltext">+{temperature} ºC</p>}
                        />
                        <Pill
                            leadingIcon={<img src={Moisture} className="mr-2 object-cover" alt="Umity" />}
                            children={<p className="font-poppins font-medium text-h2vsmalltext">75%</p>}
                        />
                        <Pill
                            children={
                                <div className="flex flex-row items-center mr-2">
                                    <p className="font-poppins font-medium text-xs mr-2 text-center">
                                        {
                                            t("synchronizationTime").split(" ").length === 3 ?
                                                (
                                                    <>
                                                        {t("synchronizationTime").split(" ")[0] + " "}
                                                        {t("synchronizationTime").split(" ")[1]} <br />
                                                        {t("synchronizationTime").split(" ")[2]}
                                                    </>
                                                ) :
                                                (
                                                    <>
                                                        {t("synchronizationTime").split(" ")[0]}<br />
                                                        {t("synchronizationTime").split(" ")[1]}
                                                    </>
                                                )
                                        }
                                    </p>
                                    <p className="font-poppins font-medium text-h2vsmalltext">1 min</p>
                                </div>
                            }
                            trailingIcon={<IoMdTime className="w-6 h-6" />}
                        />
                    </div>
                    <div className="flex flex-row h-full items-center space-x-6">
                        <I18n />
                        <div className="flex flex-row h-full items-center space-x-3">
                            <IoSettingsOutline className="w-7 h-7 text-h2v-blue cursor-pointer" onClick={() => {setOpenConfig(true)}}/>
                            <IoNotificationsOutline className="w-7 h-7 text-h2v-blue cursor-pointer" />
                            <BsInfoCircle className="w-6 h-6 text-h2v-blue cursor-pointer" onClick={() => {setOpenInfo(true)}}/>
                        </div>
                    </div>
                </div>
                <div className="bg-white mt-4 mx-4 p-4 rounded-xl">
                    <p className="font-poppins text-base font-semibold">
                        {t("photocatalyticPanel")}
                    </p>

                    <div className="flex flex-row items-center mt-10 mb-4 mx-10 resize-none">
                        <div className="flex flex-col items-start w-[186px] h-[264px] relative">
                            <Box.Root className="w-40 border-h2v-orange border-2 mt-4 relative z-10">
                                <div className="flex absolute h-6 w-32 -rotate-90 -left-[65px] top-1/2 transform -translate-y-1/2 bg-h2v-orange p-1 rounded-sm items-center justify-center">
                                    <span className="text-xs text-white text-nowrap font-medium">
                                        {t("basicSolutionTank")}
                                    </span>
                                </div>
                                <Box.MultipleContent>
                                    <div className="flex flex-col items-center justify-center w-28 h-16 bg-white rounded-md">
                                        <div className="flex flex-row justify-between w-full px-2">
                                            <span className="text-sm">{t("level")}</span>
                                            <Temperature />
                                        </div>
                                        <span className="font-bold text-h2v-green text-2xl">{tkSoluctionBasicLevel}</span>
                                    </div>
                                    <div className="flex flex-col items-start px-1 justify-center w-28 h-16 bg-white border border-h2v-green rounded-md">
                                        <span className="text-sm text-nowrap">{t("targetpH")}</span>
                                        <span className="self-center font-bold text-h2v-green text-2xl">{tkSoluctionBasicPh}</span>
                                    </div>
                                </Box.MultipleContent>
                            </Box.Root>
                            <div className="absolute z-20 right-12 bottom-0 border-2 rounded-md border-h2v-orange bg-white">
                                <OrangeWaterPump className="m-2" />
                            </div>
                            <OrangeArrowBr className="relative z-0 bottom-[10px] ml-10" />
                        </div>

                        <div className="flex flex-row items-center relative">
                            <div className="flex flex-col items-start w-[287px]">
                                <Box.Root className="w-40 border-h2v-blue border-2 z-10">
                                    <div className="flex absolute h-6 w-28 -rotate-90 -left-[55px] top-1/2 transform -translate-y-1/2 bg-h2v-blue p-1 rounded-sm items-center justify-center">
                                        <span className="text-xs text-white text-nowrap font-medium">
                                            {t("glycerolTank")}
                                        </span>
                                    </div>
                                    <Box.MultipleContent>
                                        <div className="flex flex-col items-center justify-center w-28 h-16 bg-white rounded-md">
                                            <div className="flex flex-row items-center justify-between w-full px-2">
                                                <span className="text-sm">{t("level")}</span>
                                                <Temperature />
                                            </div>
                                            <span className="font-bold text-h2v-red text-2xl">{tkGlycerolLevel}</span>
                                        </div>
                                        <div className="flex flex-col items-start justify-center w-28 h-16 bg-white rounded-md">
                                            <div className="flex flex-row items-center justify-between w-full px-2">
                                                <span className="text-sm">{t("temperature")}</span>
                                                <FaTemperatureHalf />
                                            </div>
                                            <span className="self-center font-bold text-2xl">{tkGlycerolTemperature}º</span>
                                        </div>
                                        <div className="flex flex-col items-start px-2 justify-center w-28 h-16 bg-white border border-h2v-red rounded-md">
                                            <span className="text-sm text-nowrap">{t("pump")}</span>
                                            <span className="self-center font-bold text-2xl">{tkGlycerolPump}</span>
                                        </div>
                                        <div className="flex flex-col items-start px-2 justify-center w-28 h-16 bg-white rounded-md">
                                            <span className="text-sm text-nowrap">{t("actualpH")}</span>
                                            <span className="self-center font-bold text-2xl text-h2v-red">{tkGlycerolPh}</span>
                                        </div>
                                    </Box.MultipleContent>
                                </Box.Root>
                            </div>
                            <div className="absolute z-10 right-10 border-2 rounded-md border-h2v-blue bg-white">
                                <BlueWaterPump className="m-2" />
                            </div>
                            <BlueArrowR className="absolute right-0 z-0" />
                        </div>

                        <div className="flex flex-col self-start w-[415px]">
                            <div className="flex flex-col h-20 border-2 border-black rounded-md relative top-[1px] z-10 w-36 right-10">
                                <div className="flex flex-row justify-between items-center px-2 py-1 space-x-2">
                                    <span className="text-sm">{t("luminosity")}</span>
                                    <IoSunnyOutline className="text-yellow-500 w-6 h-6" />
                                </div>
                                <span className="self-center font-bold text-2xl">{luminosity}</span>
                            </div>

                            <div className="flex items-center">
                                <hr className="relative w-1 h-8 bg-black mx-8 right-2" />
                                <span className="self-center">{t("reactor")}</span>
                            </div>

                            <div className="flex flex-row items-center relative">
                                <Box.Root className="w-48 h-48 border-black border-4 relative overflow-hidden">
                                    <Reactor className="absolute -bottom-1 w-full z-0" />
                                    <Box.Content className="relative z-10">
                                        <div className="h-full flex flex-col items-center mt-12">
                                            <span className="text-white font-bold">Produção de H₂</span>
                                            <span className="text-4xl text-white font-bold">82%</span>
                                        </div>
                                    </Box.Content>
                                </Box.Root>

                                <BlueArrowTR className="absolute mb-12 ml-[192px]" />
                                <BlueTube className="absolute mb-[263px] ml-[260px] z-10" />
                                <BlueArrowRBL className="absolute -ml-[128px] mt-3 z-20" />
                                <div className="absolute mb-[300px] ml-[400px] z-10 bg-white">
                                    <div className="flex flex-col items-center border-2 border-black rounded-md z-10">
                                        <div className="flex flex-row items-center px-2 py-1 space-x-2">
                                            <span className="text-sm text-nowrap">{t("gasFlow")}</span>
                                            <FaWind className="text-black opacity-60" />
                                        </div>
                                        <span className="self-center font-bold text-2xl">{gasFlow}</span>
                                    </div>
                                </div>
                                <GreenArrowTRB className="absolute mb-[260px] ml-[303px] z-0" />
                            </div>
                        </div>

                        <div className="flex flex-row items-center space-x-4 relative">
                            <div className="flex flex-col items-start justify-end">
                                <div className="absolute bottom-36 left-[30px] flex items-center space-x-4">
                                    <div className="w-2 h-3 bg-black"></div>
                                    <div className="w-2 h-3 bg-black"></div>
                                </div>
                                <div className="absolute space-y-20 z-0">
                                    <hr className="bottom-[122px] absolute w-px left-[57px] h-8 bg-black border-black" />
                                    <hr className="bottom-10 absolute w-px left-[33px] h-[104px] bg-black border-black" />
                                </div>
                                <div className="absolute space-y-20 bottom-10 z-0">
                                    <hr className="relative w-14 left-[57px] h-px bg-black border-black" />
                                    <hr className="relative w-20 left-[33px] h-px bg-black border-black" />
                                </div>
                                <GreenTube className="mt-[155px]" />
                            </div>
                            <div className="flex flex-col space-y-4 self-end z-10 bg-white">
                                <div className="flex flex-col items-center w-20 h-16 border-2 border-black rounded-md">
                                    <span>H₂</span>
                                    <span className="self-center font-bold text-2xl">500</span>
                                </div>
                                <div className="flex flex-col items-center w-20 h-16 border-2 border-black rounded-md">
                                    <span>CO₂</span>
                                    <span className="self-center font-bold text-2xl">500</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row bg-white mt-4 mx-4 p-4 rounded-xl justify-end space-x-4 items-center">
                    <p>{t("viewPeriod")}</p>
                    <Select
                        value={fromDay}
                        options={Array.from({ length: 31 }, (_, index) => {
                            const number = (index + 1).toString().padStart(2, '0')
                            return {
                                value: number,
                                label: number
                            }
                        })}
                        onChange={(e) => setFromDay(e.target.value)}
                        className="appearance-none cursor-pointer w-16 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 px-4 rounded leading-tight focus:outline-none"
                        iconColor="text-h2v-blue"
                    />
                    <Select
                        value={fromMonth}
                        options={months}
                        onChange={(e) => setFromMonth(e.target.value)}
                        className="appearance-none cursor-pointer h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                        iconColor="text-h2v-blue"
                    />
                    <Select
                        value={fromYear}
                        options={Array.from({ length: 2024 - 1900 + 1 }, (_, index) => {
                            const number = (1900 + index).toString()
                            return {
                                value: number,
                                label: number
                            }
                        })}
                        onChange={(e) => setFromYear(e.target.value)}
                        className="appearance-none cursor-pointer w-24 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                        iconColor="text-h2v-blue"
                    />
                    <p>{t("until")}</p>
                    <Select
                        value={toDay}
                        options={Array.from({ length: 31 }, (_, index) => {
                            const number = (index + 1).toString().padStart(2, '0')
                            return {
                                value: number,
                                label: number
                            }
                        })}
                        onChange={(e) => setToDay(e.target.value)}
                        className="appearance-none cursor-pointer w-16 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 px-4 rounded leading-tight focus:outline-none"
                        iconColor="text-h2v-blue"
                    />
                    <Select
                        value={toMonth}
                        options={months}
                        onChange={(e) => setToMonth(e.target.value)}
                        className="appearance-none cursor-pointer h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                        iconColor="text-h2v-blue"
                    />
                    <Select
                        value={toYear}
                        options={Array.from({ length: 2024 - 1900 + 1 }, (_, index) => {
                            const number = (1900 + index).toString()
                            return {
                                value: number,
                                label: number
                            }
                        })}
                        onChange={(e) => setToYear(e.target.value)}
                        className="appearance-none cursor-pointer w-24 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                        iconColor="text-h2v-blue"
                    />
                </div>
                <div className="grid m-4 gap-6 grid-cols-2">
                    <div className="w-full h-full flex flex-col bg-white p-4 px-6 rounded-xl">
                        <p className="font-poppins text-base font-semibold">{t("productionVolume")}</p>
                        <div className="w-full h-full mt-4">
                            <LineChart data={[20, 22, 23, 40, 43, 55, 60, 100, 20]} />
                        </div>
                    </div>
                    <div className="w-full h-full flex flex-col bg-white p-4 px-6 rounded-xl">
                        <div className="flex flex-row justify-between mb-2">
                            <p className="font-poppins text-base font-semibold">{t("lastCommands")}</p>
                            <p className="font-poppins font-semibold text-h2v-green cursor-pointer">{t("seeAll")}</p>
                        </div>
                        <div className="overflow-x-auto overflow-y-auto max-h-full">
                            <table className="min-w-full table-fixed">
                                <tbody className="divide-y-2">
                                    {commands.map((item, index) => (
                                        <tr key={index} className="flex justify-between py-6 px-4 items-center">
                                            <td>{item.command}</td>
                                            <td>
                                                <div className="flex items-center space-x-4">
                                                    <p>{item.from}%</p>
                                                    <FaArrowRightLong />
                                                    <p>{item.to}%</p>
                                                </div>
                                            </td>
                                            <td className="flex items-center">
                                                <div className={`w-2 h-2 rounded-full ${item.status === t("waiting") ? "bg-yellow-500" : ""} mr-2`}></div>
                                                {item.status}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="w-full h-full flex flex-col bg-white p-4 px-6 rounded-xl overflow-x-auto">
                        <p className="font-poppins text-base font-semibold">{t("reactor")}</p>
                        <div className="flex flex-row space-x-4 mt-4">
                            <div className="w-1/2 flex flex-col border-2 border-h2v-blue rounded-lg p-2">
                                <div className="flex flex-row justify-between items-center">
                                    <p className="font-poppins font-light text-sm">{t("gasFlow")}</p>
                                    <p className="font-poppins font-semibold text-lg text-h2v-green">3500ppm</p>
                                </div>
                                <LinePointedChart data={[2000, 3000, 4000, 2500, 1500, 1700, 500, 3500]} min={0} max={4000} stepSize={1000} color={'rgba(87, 151, 15, 1)'} fillColor={'rgba(87, 151, 15, 0.2)'} />
                            </div>
                            <div className="w-1/2 flex flex-col border-2 border-h2v-blue rounded-lg p-2">
                                <div className="flex flex-row justify-between items-center">
                                    <p className="font-poppins font-light text-sm">{t("temperature")}</p>
                                    <p className="font-poppins font-semibold text-lg text-h2v-green">40º</p>
                                </div>
                                <LinePointedChart data={[0, 4, 8, 9, 10, 14, 12, 0]} min={0} max={14} stepSize={4} color={'rgba(87, 151, 15, 1)'} fillColor={'rgba(87, 151, 15, 0.2)'} />
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-full flex flex-col bg-white p-4 px-6 rounded-xl overflow-x-auto">
                        <p className="font-poppins text-base font-semibold">{t("glycerolTank")}</p>
                        <div className="flex flex-row space-x-4 mt-4">
                            <div className="w-1/2 flex flex-col border-2 border-h2v-blue rounded-lg p-2">
                                <div className="flex flex-row justify-between">
                                    <p className="font-poppins font-light text-sm">Ph</p>
                                    <p className="font-poppins font-semibold text-lg text-h2v-orange">14</p>
                                </div>
                                <LinePointedChart data={[0, 4, 8, 9, 10, 14, 12, 0]} min={0} max={14} stepSize={4} color={"rgba(241, 90, 37, 1)"} fillColor={"rgba(241, 90, 37, 0.2)"} />
                            </div>
                            <div className="w-1/2 flex flex-col border-2 border-h2v-blue rounded-lg p-2">
                                <div className="flex flex-row justify-between">
                                    <p className="font-poppins font-light text-sm">{t("temperature")}</p>
                                    <p className="font-poppins font-semibold text-lg text-h2v-orange">40º</p>
                                </div>
                                <LinePointedChart data={[0, 4, 8, 9, 10, 14, 12, 0]} min={0} max={14} stepSize={4} color={"rgba(241, 90, 37, 1)"} fillColor={"rgba(241, 90, 37, 0.2)"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openInfo && (
                <Modal.Root className={"lg:w-96"}>
                    <Modal.Content className={"justify-items-start mx-4"} iconButton={
                            <Button
                                onClick={() => {setOpenInfo(false)}}
                                children={<MdOutlineClose className="w-8 h-8"/>}
                            />
                        }>
                        <Modal.Title text={t("panelData")} className={"font-semibold"} />
                        <div className="flex flex-col space-y-4">
                            <div className="flex flex-col">
                                <span>{t("installationDate")}:</span>
                                <span className="font-bold">{installationDate}</span>
                            </div>
                            <div className="flex flex-col">
                                <span>{t("lastSync")}:</span>
                                <span className="font-bold">{elapsedTime}</span>
                            </div>
                            <div className="flex flex-col">
                                <span>{t("installationAddress")}:</span>
                                <span className="font-bold">{installationAddress}</span>
                            </div>
                            <div className="flex flex-col">
                                <span>{t("productionNominalCapacity")}:</span>
                                <span className="font-bold">{nominalCapacity}</span>
                            </div>
                        </div>
                    </Modal.Content>
                </Modal.Root>
            )}
            {openConfig && (
                <Modal.Root>
                    <Modal.Content>
                        <Modal.Title text={t("panelConfig")} />
                        <Modal.Form onSubmit={configPanel}>
                            <div className="flex flex-col space-y-0 lg:space-y-3">
                                <Input.Root className={"w-40"}>
                                    <Input.Label text={t("installationDate")} className={"text-h2v-blue"} />
                                    <Input.Content id="installationDate" type="date" value={instDate} onChange={(e) => setInstDate(e.target.value)}/>
                                </Input.Root>
                                <Input.Root>
                                    <Input.Label text={t("installationAddress")} className={"text-h2v-blue"} />
                                    <Input.Content id="installationAddress" type="text" value={instAdr} onChange={(e) => setInstAdr(e.target.value)}/>
                                </Input.Root>
                                <div className="flex flex-row items-center space-x-4">
                                    <Input.Root>
                                        <Input.Label text={t("nominalCapacity")} className={"text-h2v-blue"} />
                                        <Input.Content id="nominalCapacity" type="number" value={nomCapc} onChange={(e) => setNomCapc(e.target.value)}/>
                                    </Input.Root>
                                    <Input.Root>
                                        <Input.Label text={t("elapsedTime")} className={"text-h2v-blue"} />
                                        <Input.Content id="elapsedTime" type="number" value={elsTime} onChange={(e) => setElsTime(e.target.value)}/>
                                    </Input.Root>
                                </div>
                            </div>
                            <Modal.Bottom>
                                <Button
                                    children={t("cancel")}
                                    className="self-center bg-h2v-orange font-poppins text-base text-white font-medium py-2 px-4 w-32 lg:w-48 rounded-lg"
                                    onClick={() => {setOpenConfig(false)}}
                                />
                                <Button
                                    type="submit"
                                    children={t("save")}
                                    className="self-center bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-32 lg:w-48 rounded-lg"
                                />
                            </Modal.Bottom>
                        </Modal.Form>
                    </Modal.Content>
                </Modal.Root>
            )}
        </div>
    )
}
