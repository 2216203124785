import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ReactComponent as WhiteLogo } from "../../assets/white_logo.svg"
import { ReactComponent as WhiteIcon } from "../../assets/white-icon.svg"
import {
    MdPerson,
    MdDashboard,
    MdAccountBox,
    MdInsertChartOutlined,
    MdOutlineClose,
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight
} from "react-icons/md"
import { useLocation } from "react-router-dom"
import { useState, useEffect } from "react"
import { userLogout } from '../../features/auth/authActions'
import { getUser, updateUser, updateUserEmail } from '../../features/user/userActions.js'
import { changeOpenState } from '../../features/sideBar/sideBarSlice.js'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from "../../components/Input/index.jsx"
import { Button } from "../../components/Button/Button.jsx"
import { Modal } from "../../components/Modal/index.jsx"
import { validateEmail } from "../../utils/utils.js"
import { resetUpdatingEmailSuccess, resetUpdatingUserSuccess } from "../../features/user/userSlice.js";
import { formatPhoneNumber, removePhoneFormatting } from "../../utils/utils.js"
import { NavItem } from "./NavItem.jsx"
import { LogoutButton } from "./LogoutItem.jsx"

export function Sidebar() {
    const navigate = useNavigate()
    const [isOpened, setOpen] = useState(false)
    const [isEditting, setIsEditting] = useState(false)
    const [isEdittingEmail, setIsEdittingEmail] = useState(false)
    const { t } = useTranslation()
    const path = useLocation().pathname
    const [isEmailValid, setIsEmailValid] = useState(true)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")

    const { success, error, user } = useSelector(
        (state) => state.user
    )

    const { id } = useSelector(
        (state) => state.auth
    )

    const { openState } = useSelector(
        (state) => state.sideBar
    )

    const dispatch = useDispatch()

    const changeState = () => {
        dispatch(changeOpenState())
    }

    const openPerfil = () => {
        dispatch(getUser({ id }))
        setOpen(true)
    }

    const logout = () => {
        dispatch(userLogout())
        navigate("/", { replace: true })
    }

    const editUser = (e) => {
        e.preventDefault()

        dispatch(updateUser({ id, name, phone: removePhoneFormatting(phone) }))
    }

    const editEmail = (e) => {
        e.preventDefault()
        if (!validateEmail(email)) {
            setIsEmailValid(false)
            return
        }
        
        dispatch(updateUserEmail({ id, email }))
    }

    useEffect(() => {
        if (user) {
            setName(user.name)
            setEmail(user.email)
            setPhone(user.phone)
        }

        if (success.updatingUser) {
            dispatch(resetUpdatingUserSuccess())
            setIsEditting(false)
        }

        if (success.updatingEmail) {
            dispatch(resetUpdatingEmailSuccess())
            setIsEdittingEmail(false)
        }

        if (error) {
            console.error("Failed to update user:", error)
        }
    }, [success, dispatch, user, error])

    return (
        <aside className={`top-0 left-0 z-30 ${openState ? "absolute lg:relative block lg:w-auto" : "hidden"} 
        lg:w-80 w-20 h-full transition-transform lg:block`} aria-label="Sidebar">
            <div className="h-full relative">
                <button
                    onClick={() => changeState()}
                    className="z-40 absolute top-4 right-[-15px] bg-white p-1 rounded-full shadow-md"
                >
                    {openState ? (
                        <div>
                            <MdKeyboardArrowRight className="hidden lg:block text-black w-6 h-6" />
                            <MdKeyboardArrowLeft className="block lg:hidden text-black w-6 h-6" />
                        </div>
                    ) : (
                        <div>
                            <MdKeyboardArrowLeft className="hidden lg:block text-black w-6 h-6" />
                        </div>
                    )}
                </button>
                <div className="overflow-hidden h-full py-12 sm:py-8 lg:py-6 bg-h2v-green flex flex-col items-center">
                    <div className="flex w-full justify-center lg:pt-5">
                        <WhiteLogo className={`${openState ? "hidden" : "lg:block"} w-44 px-3 h-auto pb-4`} />
                        <WhiteIcon className={`${openState ? "block" : "hidden"} w-20 lg:w-24 sm:w-16 px-3 h-20 lg:h-24 pb-4`} />
                    </div>
                    <div className="flex flex-col flex-grow w-full items-center">
                        <div className={`w-full lg:grid ${openState ? "lg:grid-cols-0" : "lg:grid-cols-6"} h-full`}>
                            <div className={`hidden ${openState ? null : "lg:block"}`}></div>
                            <div className={`w-full ${openState ? "lg:col-span-0" : "lg:col-span-5"} flex flex-col items-center`}>
                                <ul className="lg:w-full h-full font-poppins font-medium py-8 lg:py-8 sm:py-2 flex-grow space-y-6 lg:space-y-6 sm:space-y-2">
                                    <NavItem
                                        path={"/perfil"}
                                        currentPath={path}
                                        onClick={openPerfil}
                                        Icon={MdPerson}
                                        label={t("perfil")}
                                    />
                                    <NavItem
                                        path={"/dashboard"}
                                        currentPath={path}
                                        onClick={() => path !== "/dashboard" && navigate("/dashboard")}
                                        Icon={MdDashboard}
                                        label={t("dashboard")}
                                    />
                                    <NavItem
                                        path={"/collaborators"}
                                        currentPath={path}
                                        onClick={() => path !== "/collaborators" && navigate("/collaborators")}
                                        Icon={MdAccountBox}
                                        label={t("collaborators")}
                                    />
                                    <NavItem
                                        path={"/report"}
                                        currentPath={path}
                                        onClick={() => path !== "/report" && navigate("/report")}
                                        Icon={MdInsertChartOutlined}
                                        label={t("report")}
                                    />
                                </ul>

                            </div>
                            <div className={`flex ${openState ? "lg:col-span-0" : "lg:col-span-6"} justify-center`}>
                                <LogoutButton onClick={logout} label={t("logout")} />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isOpened && user && (
                        <Modal.Root>
                            <Modal.Content
                                iconButton={
                                    <Button
                                        className="flex bg-gray-200 rounded-lg items-center justify-center p-4"
                                        onClick={() => setOpen(false)}
                                        children={<MdOutlineClose />}
                                    />
                                }
                            >
                                <Modal.Title text={t("perfil")} />
                                <div className="w-full px-14 grid grid-cols-2 gap-4">
                                    <div className="space-y-2">
                                        <Input.Root>
                                            <Input.Label text={t("name")} />
                                            <Input.Content value={user.name} disabled={true} className={"border-0"} />
                                        </Input.Root>
                                        <Input.Root>
                                            <Input.Label text={t("phone")} />
                                            <Input.Content value={formatPhoneNumber(user.phone)} disabled={true} className={"border-0"} />
                                        </Input.Root>
                                        <p className="text-start text-h2v-green text-sm underline underline-offset-2 cursor-pointer" onClick={() => { setOpen(false); setIsEdittingEmail(true)}}>
                                            {t("changeEmail")}
                                        </p>
                                    </div>
                                    <div className="space-y-2">
                                        <Input.Root>
                                            <Input.Label text={t("perfil")} />
                                            <Input.Content
                                                value={t(user.role === "ADMINISTRADOR" ? "administrator" : "operator")}
                                                disabled={true}
                                                className={"border-0"}
                                            />
                                        </Input.Root>
                                        <Input.Root>
                                            <Input.Label text={t("email")} />
                                            <Input.Content value={user.email} disabled={true} className={"border-0"} />
                                        </Input.Root>
                                        <p className="text-end text-h2v-green text-sm underline underline-offset-2 cursor-pointer" onClick={() => navigate("/redefine-password", { replace: true })}>
                                            {t("changePassword")}
                                        </p>
                                    </div>
                                </div>
                                <Modal.Bottom>
                                    <Button
                                        children={t("edit")}
                                        className="self-center bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-48 rounded-lg"
                                        onClick={() => { setOpen(false); setIsEditting(true) }}
                                    />
                                </Modal.Bottom>
                            </Modal.Content>
                        </Modal.Root>
                    )
                }
                {
                    isEditting && (
                        <Modal.Root>
                            <Modal.Content
                                iconButton={
                                    <Button
                                        className="flex bg-gray-200 rounded-lg items-center justify-center p-4"
                                        onClick={() => setIsEditting(false)}
                                        children={<MdOutlineClose />}
                                    />
                                }
                            >
                                <Modal.Title text={t("editPerfilInfo")} />
                                <Modal.Form onSubmit={editUser}>
                                    <Input.Root>
                                        <Input.Label text={t("name")} />
                                        <Input.Content value={name} onChange={(e) => setName(e.target.value)} placeholder={t("name")} />
                                    </Input.Root>
                                    <Input.Root>
                                        <Input.Label text={t("phone")} />
                                        <Input.Content value={formatPhoneNumber(phone)} onChange={(e) => setPhone(e.target.value)} placeholder={t("phone")} />
                                    </Input.Root>
                                    <Modal.Bottom>
                                        <Button
                                            children={t("cancel")}
                                            className="self-center bg-h2v-orange font-poppins text-base text-white font-medium py-2 px-4 w-48 rounded-lg"
                                            onClick={() => setIsEditting(false)}
                                        />
                                        <Button
                                            type="submit"
                                            children={t("save")}
                                            className="self-center bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-48 rounded-lg"
                                        />
                                    </Modal.Bottom>
                                </Modal.Form>
                            </Modal.Content>
                        </Modal.Root>
                    )
                }
                {
                    isEdittingEmail && (
                        <Modal.Root>
                            <Modal.Content
                                iconButton={
                                    <Button
                                        className="flex bg-gray-200 rounded-lg items-center justify-center p-4"
                                        onClick={() => setIsEdittingEmail(false)}
                                        children={<MdOutlineClose />}
                                    />
                                }
                            >
                                <Modal.Title text={t("editPerfilInfo")} />
                                <Modal.Form onSubmit={editEmail}>
                                    <Input.Root>
                                        <Input.Label text={t("email")} />
                                        <Input.Content
                                            value={email}
                                            onChange={(e) => { setEmail(e.target.value); if (!isEmailValid) setIsEmailValid(true) }}
                                            placeholder={t("email")}
                                            className={`${!isEmailValid ? "border-red-500 focus:border-red-500" : ""}`}
                                        />
                                        {!isEmailValid && (
                                            <div className="absolute text-red-500 text-sm">{t("invalidEmail")}</div>
                                        )}
                                    </Input.Root>
                                    <Modal.Bottom>
                                        <Button
                                            children={t("cancel")}
                                            className="self-center bg-h2v-orange font-poppins text-base text-white font-medium py-2 px-4 w-48 rounded-lg"
                                            onClick={() => setIsEdittingEmail(false)}
                                        />
                                        <Button
                                            type="submit"
                                            children={t("save")}
                                            className="self-center bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-48 rounded-lg"
                                        />
                                    </Modal.Bottom>
                                </Modal.Form>
                            </Modal.Content>
                        </Modal.Root>
                    )
                }
            </div >
        </aside >
    )
}
