import agent from "./api";


const PANEL = "/panel"

export async function getPanel() {
    return await agent.get(PANEL)
}

export async function postPanel(installationAddress, installationDate, nominalCapacity, elapsedTime) {
    return await agent.post(PANEL, { installationAddress, installationDate, nominalCapacity, elapsedTime })
}

export async function getDashboard() {
    return await agent.get(PANEL + "/dashboard")
}